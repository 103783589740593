const colors = {
  green: {
    '100': '#84F0B6',
    '400': '#5BEB9D',
    '500': '#38A169',
    '600': '#25855A',
  },
  blue: {
    '50': '#EBF8FF',
    '100': '#BEE3F8',
    '200': '#90CDF4',
    '300': '#80CCFF',
    '400': '#4BB7FF',
    '500': '#3182CE',
    '600': '#2B6CB0',
    '700': '#2C5282',
    '800': '#2A4365',
    '900': '#002E4C',
  },
  red: {
    '100': '#FFE6EB',
    '200': '#FFB8C3',
    '300': '#FF8D96',
    '400': '#FF616A',
    '500': '#F04275',
  },
  orange: {
    '100': '#FFCCB2',
  },
  gray: {
    '50': '#F7FAFC', // <-
    '100': '#F5F5F7',
    '200': '#E0E0E1',
    '300': '#CBD5E0',
    '400': '#A2A2A4',
    '500': '#7F7F80',
    '600': '#454549',
    '700': '#343437',
    '800': '#232325',
    '900': '#1A1A1A',
  },
  black: '#000000',
  white: '#F5F5F7',
  blackAlpha: {
    '50': 'RGBA(16, 17, 18, 0.04)',
    '100': 'RGBA(16, 17, 18, 0.06)',
    '200': 'RGBA(16, 17, 18, 0.08)',
    '300': 'RGBA(16, 17, 18, 0.16)',
    '400': 'RGBA(16, 17, 18, 0.24)',
    '500': 'RGBA(16, 17, 18, 0.36)',
    '600': 'RGBA(16, 17, 18, 0.48)',
    '700': 'RGBA(16, 17, 18, 0.64)',
    '800': 'RGBA(16, 17, 18, 0.80)',
    '900': 'RGBA(16, 17, 18, 0.92)',
  },
  github: '#171923',
  telegram: '#2775CA',
  linkedin: '#1564BA',
  discord: '#9747FF',
  slack: '#1BA27A',
  twitter: '#63B3ED',
  opensea: '#2081E2',
  facebook: '#4460A0',
  medium: '#231F20',
  reddit: '#FF4500',
  purple: {
    '50': '#F5F3FF',
    '100': '#EDE9FE',
    '200': '#DDD6FE',
    '300': '#B480FF',
    '400': '#9A52FF',
    '500': '#7919FF',
    '600': '#5F00B2',
    '700': '#4A00B2',
    '800': '#350080',
    '900': '#20004C',
  },
};

export default colors;
